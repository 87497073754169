import React, { useState, useEffect }  from 'react'
import { useParams } from 'react-router-dom'
import Posts from './Posts'
import Pagination from './Pagination'
import { getCategory, getCategoryProduct } from '../../HTTP/https'


const SubCategoryPage = () => {
  const {id} = useParams()
  const [data, setData] = useState('')
  const [dataCategories, setDataCategories] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(15)
    

const apiCall = async () =>{
  const {data} = await getCategory(id)
  setData(data)
}

useEffect(() =>{
    apiCall()
},[])

const apiCallCategories = async () =>{
  const {data} = await getCategoryProduct(id)
  setDataCategories(data)
}

useEffect(() =>{
  apiCallCategories()
},[])
 
const paginate = (pageNumber) =>{
  setCurrentPage(pageNumber)
}

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = dataCategories.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div>
      <div className='banner_path mt-10'>

          <img src={data && data?.description?.banner_path} alt='baner'/>

        <p className='mt-12'>{data && data?.description?.description}</p>
      </div>

      <div className='mt-10'>
        <Posts posts={currentPosts} />
      </div>
    <Pagination 
      postsPerPage={postsPerPage} 
      totalPosts={dataCategories.length} 
      paginate={paginate}
      currentPage={currentPage}
    />
   
    </div>
  )
}

export default SubCategoryPage