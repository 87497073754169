import React, { useState, useEffect } from 'react'
import config from '../../config'

const Action = () => {

  const [data, setData] = useState('')
    
const apiCall = async () =>{
  const response = await fetch(config.api.requestActions)
  const dataResponse = await response.json()
  dataResponse.data.sort(function(a, b) {
    return a.order - b.order;
  });
  setData(dataResponse.data)
}

useEffect(() =>{
    apiCall()
},[])

  return (
    <div className='grid grid-cols-3 gap-10 mt-10'>
      {data && data.map((item, index) =>{
        return(
          <div key={index}>
            <img className='w-[100%]' src={item.image} />
          </div>
        )
      })}

    </div>
  )
}

export default Action