import React, { useEffect, useState } from 'react'
import axiosInstance from '../../cms/HTTP/AxiosInstance'
import Brands from '../../components/Brands/Brands'
import Categories from '../../components/Categories/Categories'
import ProductBanerAndMilk from '../../components/ProductsBaner/ProductBanerAndMilk'
import ProductsBaner from '../../components/ProductsBaner/ProductsBaner'
import ProductsTreeBaners from '../../components/ProductsBaner/ProductsTreeBaners'
import ProductTreeAndFour from '../../components/ProductsBaner/ProductTreeAndFour'
import ProductTwoBaners from '../../components/ProductsBaner/ProductTwoBaners'
import SwiperProducts from '../../components/SwiperProducts/SwiperProducts'
import config from '../../config'
import axios from 'axios'
import Baner from './Baner'
import ListBaner from './ListBaner'
import OurOffer from './OurOffer'
import { getHomeSections } from '../../HTTP/https'

const HomePage = () => {
  
  const [data, setData] = useState('')
    
const apiCall = async () =>{
  const {data} = await getHomeSections()
        data.sort(function(a, b) {
        return a.order - b.order;
    });
    setData(data)
}

useEffect(() =>{
    apiCall()
},[])

  return (
    <>
      <div className='flex flex-col md:grid grid-cols-5 mt-3 '>
        <div className='order-2 md:order-1'>
          <ListBaner />
        </div>
        <div className='order-1 md:order-2 col-span-4 ml-4'>
          <Baner />
        </div>
      </div>
      <OurOffer />
      {data && data.map((item, index) =>{
        switch (item.type.name){
          case "Proizvodi":
            return (
              <SwiperProducts key={index} data={item} />
            );
              break;
          case "Baner":
            return(
              <ProductsBaner key={index} data={item} />
            );
              break;
          case "Tri banera":
            return(
              <ProductsTreeBaners key={index} data={item} />
            );
              break;
          case "Dva banera 1:1":
            return(
              <ProductTwoBaners key={index} data={item} />
            );
              break;
          case "Baner i proizvod":
            return(
              <ProductBanerAndMilk key={index} data={item} />
            );
              break;
          case "Kategorije":
            return(
              <Categories key={index} data={item} />
            );
              break;
          case "Dva banera 3:4":
            return(
              <ProductTreeAndFour key={index} data={item} />
            );
              break;
            default: 
              break;
        }
      })}
      <Brands />
    </>
  )
}

export default HomePage