import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar, Navigation, A11y } from 'swiper';
import icon from '../../assets/icons/Ellipse.png'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperItem from './SwiperItem';
import './SwiperProducts.scss'

const SwiperProducts = ({data}) => {

  return (
    <div className='swiper_produt'>
      <p className='swiper_product_name flex justify-start items-center'>
        <img className='mr-2' src={icon}  alt={data.name}/>{data.name}
        </p>
      <Swiper className='mt-10'
      modules={[ Navigation, Scrollbar, A11y]}
      navigation
      spaceBetween={0}
      slidesPerView={4}
      breakpoints={{
        0:{
          slidesPerView: 2,
          spaceBetween: 10,
        },
        426: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        }
      }}
      >
        {data.products && data.products.map((item,index) =>{
          return (
              <SwiperSlide key={index}>
                <SwiperItem item={item} />
              </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default SwiperProducts
