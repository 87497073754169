import React from 'react'

const Shops = () => {
  return (
    <div className='mt-10'>
        <h2>PRODAVNICE</h2>
        <div className='mt-6'>
        <ul>
          <li>Knjaževačka bb, Niš</li>
          <li>Somborska 47a, Niš</li>
          <li>Prvomajska 1, Niš</li>
          <li>Čarnojevića 10, Niš</li>
          <li>Hilandarska 27, Niš</li>
          <li>Sime Dinića 3, Niš</li>
          <li>Miloša Obilića, Matejevac</li>
        </ul>
      </div>
    </div>
  )
}

export default Shops