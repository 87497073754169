import React from 'react'
import { Link } from 'react-router-dom'

const SwiperItem= ({item}) => {
  return (
    <div>
        <div>
            <Link to={`/product/${item.name}`} >

            <div className="rounded overflow-hidden flex flex-col items-center">
              <img className="h-[180px]" src={item && item.image} alt="no-image" />
              <div className="px-6 py-4">
                <p className='product_name'>{item.name}</p>
                <p className='old_price'>{item.recommended_price} RSD</p>
                <p className='price'>{item.price} RSD</p>
                <p className='save_money'>Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD</p>
              </div>
            </div>
            </Link>
        </div>
    </div>
  )
}

export default SwiperItem