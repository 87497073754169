import React, { useState, useEffect } from 'react'
import { BsSearch } from 'react-icons/bs'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import './Search.scss'

const Search = ({placeholder}) => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])


  const getApi = async () =>{
    const response = await fetch(`https://as-trgovina.jakovss.com/api/products`)
    const dataResponse = await response.json()
    setData(dataResponse.data)
} 

  useEffect(()=>{
      getApi()
  }, [])

  const handleFilter = (event) =>{
    const searchWord = event.target.value
    const newFilter = data.filter((val) =>{
        return (
            val.name.toLowerCase().includes(searchWord.toLowerCase())
        )
    })
    if(searchWord === ''){
        setFilteredData([])
    }else{
        setFilteredData(newFilter)

    }
}

return (
<div className='search_wrapper'>
    <GiHamburgerMenu className='blok md:hidden' />
    <div className='input_field'>
        <input className='w-100' 
        onChange={handleFilter}
        type='text' 
        placeholder='Search...' />
          <BsSearch />
    </div>
    {filteredData.length !== 0 && (
            <div className='input_results'>
        {filteredData.map((value, index) =>{
            return (
                
              <Link key={index} to={`/product/${value.name}`} className='searchDetails'>
              <div className='flex'>
                <div className='search_img ml-14'>
                  <img src={value.image} alt={value.name} />
                </div>
                <div className='search_details ml-14'>
                  <p className='product_name'>{value.name}</p>
                  <p className='old_price'>{value.recommended_price} RSD</p>
                  <p className='price'>{value.price} RSD</p>
                  <p className='save_money'>Ušteda: {(value.recommended_price - value.price).toFixed(2)} RSD</p>
                </div>
              </div>
            </Link>
            )
        })}
    </div>
        )}

</div>
)
}

export default Search