import React from 'react'
import ContactBaner from './ContactBaner'
import ContactForm from './ContactForm'
import './Contact.scss'

const Contact = () => {
  return (
    <div>
        <div>
            <ContactBaner />
        </div>
        <div>
            <ContactForm />
        </div>
    </div>
  )
}

export default Contact