import React, { useState, useEffect } from 'react'
import { getApiBaners } from '../../HTTP/api'
import '../../../cms/components/AllPages/AllPages.scss'

const VisibleOnsite = () => {
  const [data, setData] = useState('')
    
  const apiCall = async () =>{
    const {data} = await getApiBaners()
    setData(data)
  }
  
  useEffect(() =>{
      apiCall()
  },[])

  return (
    <div className='all_content'>
      {data && data.map((item, index) =>{
        return (
          <div key={index} className='flex'>
            <p>{item.name}</p>
            <p>{item.id}</p>
          </div>
        )
      })}
    </div>
  )
}

export default VisibleOnsite