import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import HomePage from "./pages/Homepage/HomePage";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Product from "./pages/ProductPage/Product";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import Contact from "./pages/ContactPage/Contact";
import Footer from "./components/Footer/Footer";
import Action from "./pages/ActionPage/Action";
import SubCategoryPage from "./pages/CategoryPage/SubCategoryPage";
import Dashboard from "./cms/Dashboard/Dashboard";
import SideMenu from "./cms/components/SideMenu/SideMenu";
import AdminHeader from "./cms/components/AdminHeader/AdminHeader";
import Proizvodi from "./cms/pages/Proizvodi/Proizvodi";
import VisibleOnsite from "./cms/pages/VisibleOnSite.js/VisibleOnsite";
import LogIn from "./pages/LogIn/LogIn";
import AdminCategories from "./cms/pages/AdminCategories/AdminCategories";



function App() {

  const pathname = window.location.pathname
  const [isAdminLogedIn, setIsAdminLogedIn] = useState(false)

  const [token, setToken] = useState(localStorage.getItem('token'))

  
  const checkIfLogIn = () =>{
    setToken(localStorage.getItem('token'))
  }

  useEffect(()=>{
    if(token){
      setIsAdminLogedIn(true)
    } else{
      setIsAdminLogedIn(false)
    }
  },[token])

  return (
    <div>
        <BrowserRouter>
        {pathname.includes('/admin') && <>
        {isAdminLogedIn &&
        <>
          <AdminHeader />
          <SideMenu /> 
        </>
        }
        
        <Routes>
          <Route path="/admin" element={isAdminLogedIn? <Dashboard /> : <LogIn checkIfLogIn={() => checkIfLogIn()} />} />
          <Route path='/admin/proizvodi' element={<Proizvodi />} />
          <Route path='/admin/visibleOnSite' element={<VisibleOnsite />} />
          <Route path='/admin/categories' element={<AdminCategories />} />
        </Routes>
        </>}
      <div className="container mx-auto">
        {pathname !== '/admin' && <Header />}
          <Routes>
            <Route index element={<HomePage />} />
            <Route path='/actions' element={<Action />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/product/:name' element={<Product />} />
            <Route path='/category-products/:id' element={<SubCategoryPage />} />
            <Route path='/category/:id' element={<CategoryPage />} />
            <Route path="/admin" element={isAdminLogedIn? <Dashboard /> : <LogIn checkIfLogIn={() => checkIfLogIn()} />} />
          </Routes>
      </div>
      {pathname !== '/admin' && <Footer />}
        </BrowserRouter>
    </div>
  );
}

export default App;
