import React, { useState, useEffect} from 'react'
import config from '../../config'
import icon from '../../assets/icons/Ellipse.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Scrollbar } from 'swiper'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { getBrands } from '../../HTTP/https'

const Brands = () => {
    const [data, setData] = useState('')
    
    const apiCall = async () =>{
      const {data} = await getBrands()
      setData(data)
    }
    
    useEffect(() =>{
        apiCall()
    },[])

  return (
    <div className='mt-12'>
        <p className='brands flex justify-start items-center'>
          <img className='mr-2' src={icon} />Brendovi
          </p>
          <Swiper 
            modules={[ Navigation,Scrollbar, A11y]}
            breakpoints={{
              0:{
                slidesPerView: 2,
                spaceBetween: 5,
              },
              426: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 5,
                navigation: false
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 5,
              }
            }}
            navigation
            spaceBetween={5}
            slidesPerView={6}
            >
          {data && data.map((item, index) =>{
            return(
              <SwiperSlide key={index}>
                    <img src={item.image} alt='123' />
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
  )
}

export default Brands