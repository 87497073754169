import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation, Scrollbar } from 'swiper'
import icon from '../../assets/icons/Ellipse.png'
import './Categories.scss'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Categories = ({data}) => {
  return (
    <div className='mt-10'>
      <p className='swiper_product_name flex justify-start items-center'>
        <img className='mr-2' src={icon} />{data.name}
        </p>
        <Swiper className='categories_swiper mt-10 mx-10'
      modules={[ Navigation,Scrollbar, A11y]}
      breakpoints={{
        0:{
          slidesPerView: 2,
          spaceBetween: 5,
        },
        426: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 5,
          navigation: false
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 5,
        }
      }}
      navigation
      spaceBetween={0}
      slidesPerView={6}
      >
        {data.categories && data.categories.map((item,index) =>{
          return (
              <SwiperSlide key={index}>
                <div className='bg-[#F3F3F3] w-[189px] h-[174px] flex flex-col justify-center items-center text-center'>
                  <img className=' h-[132px]' src={item.image.image} alt={item.name} />
                  <p>{item.name}</p>
                </div>
              </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Categories