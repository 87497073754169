import React, {useState, useEffect} from 'react'
import '../../../cms/components/AllPages/AllPages.scss'
import { getApiProducts } from '../../HTTP/api'

const Proizvodi = () => {

  const [data, setData] = useState('')
    
const apiCall = async () =>{
  const {data} = await getApiProducts() 
    setData(data)
}

useEffect(() =>{
    apiCall()
},[])

  return (
    <div className='all_content'>
      <div className='nav_menu_proizvodi grid grid-cols-6 bg-[#e7e3e3] h-[50px] items-center'>
            <div>
              <input type="checkbox" /> 
            </div>
            <div>
            <p>Barcode</p>
            </div>
            <div>
            <p>Ime</p>
            </div>
            <div>
            <p>ID</p>
            </div>
            <div>
            <p>Cena</p>
            </div>
            <div>
            <p>Preporucena cena</p>
            </div>
            </div>
      <div className='grid grid-cols-1'>

      {data && data.map((item, index) =>{
        return(
          <div className='proizvodi grid grid-cols-6 py-3' key={index}>
            <div>
              <input type="checkbox" />
            </div>
            <div>
            <p>{item.barcode}</p>
            </div>
            <div>
            <p>{item.name}</p>
            </div>
            <div>
            <p>{item.id}</p>
            </div>
            <div>
            <p>{item.price} RSD</p>
            </div>
            <div>
            <p>{item.recommended_price} RSD</p>
            </div>
            
          </div>
        )
      })}
      </div>
      {/* <select>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select> */}
    </div>
  )
}

export default Proizvodi