import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/autoplay';
import { getBaners } from '../../HTTP/https';


const Baner = () => {

  const [data, setData] = useState('')

const apiCall = async () =>{
  const {data} = await getBaners()
  setData(data)
}

useEffect(() =>{
    apiCall()
},[])


  return (
    <div className='baner h-[100%]'>
      <Swiper
      className='h-[100%]'
      modules={[ Scrollbar, A11y, Autoplay]}
      autoplay={{
        delay: 10000,
      }}
      spaceBetween={0}
      slidesPerView={1}
      >
       {data && data.map((item, index) =>{
         switch(item.banner_position.name){
           case "Slajder na početnoj strani":
             return (
               <SwiperSlide key={index}>
                  <img className='h-[100%] w-[100%]' src={item.desktop_image} alt={item.name}/>
                </SwiperSlide>
               )
               break;
               default:
                 break;
                }
              })
              
            }

      </Swiper>
    </div>
  )
}

export default Baner