import React from 'react'
import img from '../../assets/icons/as-logo.png'
import { GoLocation } from 'react-icons/go'
import { FaFacebookF } from 'react-icons/fa'
import { BsInstagram, BsYoutube } from 'react-icons/bs'
import './Header.scss'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import SearchBar from './Search'


const TopHeader = () => {

  return (
    <div className='grid grid-cols-3 md:grid-cols-5 mt-3'>
      <div className='as_logo order-1 md:order-1 flex items-center justify-center'>
       <Link to={routes.HOME}><img src={img} alt='as-logo' /> </Link>
      </div>
      <div className='col-span-3 order-3 md:order-2 flex justify-center items-center w-[100%]'>
        <SearchBar placeholder='Pretrazite proizvod...' />
      </div>
      <div className='right_side order-2 col-span-2 md:order-3 md:col-span-1 flex items-center justify-end'>
        <div className='icons flex justify-end'>
          <a href='https://www.facebook.com/AsTrgovina' target='_blank' rel="noreferrer">
            <FaFacebookF />
          </a>
          <a href=''></a>
            <BsInstagram />
          <BsYoutube />
        </div>
        <div className='login'>
          <Link to='/admin'>Ulogujte se</Link>
        </div>


      </div>
    </div>
  )
}

export default TopHeader