import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import './HomePage.scss'
import routes from '../../routes'
import { getCategories } from '../../HTTP/https'


const ListBaner = () => {
    const [data, setData] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const [ index, setIndex ] = useState('')
    

const apiCall = async () =>{
  const {data} = await getCategories()
  setData(data)
}

useEffect(() =>{
    apiCall()
},[])


const [baner, setBaner] = useState('')

const apiCallBaner = async () =>{
  const response = await fetch(config.api.requestBaners)
  const dataResponse = await response.json()
  setBaner(dataResponse.data)
}

const toggle = () =>{
  setIsVisible(!isVisible)
}
useEffect(() =>{
  apiCallBaner()
},[])

  return(
    <div className='left_list'>
      <div 
      onMouseEnter={()=>toggle()}
      className='list_categories py-2'>
        {data && data.map((item, index) =>{
          return (
            <Link id={item.id} key={index} 
              onMouseEnter={()=> setIndex(index)}
              to={`/category/${item.id}`}>
                <div className='flex ml-3 my-[6px]'>
                  <img src={item && item?.icon?.icon} alt='icon' />
                  <p className='ml-3'>{item && item?.name}</p>
                </div>
            </Link>
                  )
        })}
      </div>
        {isVisible?
      <div>
        <div className='dropdown_list_item grid grid-cols-2 py-2'>
          <div>
            {(data && data[index]) &&
              data[index].subcategories.map((category, index) =>{  
                return (
                  <div className='grid' key={index}>
                    <Link to={`/category-products/${category.id}`} id={category.parent_id}>
                      {category?.name}
                    </Link>
                  </div>
                ) 
              })
            }
          </div>
          <div className='flex justify-center items-center'>
            {baner && baner.map((item, index)=>{
              switch(item.banner_position.name){
                case 'Baner u meniju':
                  return(
                    <div className='h-[100%]' key={index}>
                      <Link className='baner_link' to={routes.CONTACT}>
                        <img className='h-[100%] p-3' src={item.desktop_image} />
                      </Link>
                    </div>
                  )
                }
              })}
          </div>
        </div>
      </div>
            :null}


</div>
)

}

export default ListBaner