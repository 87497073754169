import React from 'react'
import { IoIosSend } from 'react-icons/io'
import { FaFacebookF } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { AiFillYoutube } from 'react-icons/ai'

const Contact = () => {
  return (
    <div className='contact mt-10 pb-10'>
        <h2>KONTAKT</h2>
        <div className='mt-6'>
        <ul>
          <li>18000 Niš, Medijana</li>
          <li>Bulevar Cara Konstantina 80-86</li>
          <li>018 551 551 | 018 551 950</li>
          <li>info@asmaloprodaja.com</li>
        </ul>
      </div>
      <h2 className='mt-6'>NEWSLETTER</h2>
      <div className='mt-6'>
        <div className='newsletter'>
        <input 
        placeholder='Unesite e-mail adresu'
        />
        <button>
            <IoIosSend />
        </button>
        </div>
        <p className='mt-6'>Pratite nas na društvenim mrežama</p>
        <div className='icons flex'>
          <FaFacebookF />
          <BsInstagram />
          <AiFillYoutube />
        </div>
      </div>
    </div>
  )
}

export default Contact