import React from 'react'
import { Link } from 'react-router-dom'

const Posts = ({posts}) => {
  return (
    <div className='grid grid-cols-5'>
        
        {posts?.length && posts?.map((item, index) =>{
          return(
            <Link to={`/product/${item?.name}`} className='product_details mt-10' key={index}>
              <div className="rounded overflow-hidden flex flex-col items-center">
                <img src={item?.image} alt="no-image" />
                <div className="px-6 py-4">
                <p className='product_name'>{item?.name}</p>
                <p className='old_price'>{item?.recommended_price} RSD</p>
                <p className='price'>{item?.price} RSD</p>
                <p className='save_money'>Ušteda: {(item?.recommended_price - item.price).toFixed(2)} RSD</p>
              </div>
            </div>
            </Link>
          )
        })}
        </div>         
  )
}

export default Posts