import React, { useState } from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import {BsTelephone} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import Catalog from '../Katalog/Catalog'
import { GoLocation } from 'react-icons/go'

const BottomHeader = () => {
  const [show, setShow] = useState(false)

  const toggleCatalog = () =>{
    setShow(!show)
  }

  return (
    <div className='grid grid-cols-5 mt-3'>
      <div className='all_categories hidden md:flex items-center bg-orange-400'>
        <GiHamburgerMenu />
        <p>Sve kategorije</p>
      </div>
      <div className='actions_type flex col-span-3 items-center ml-10'>
        <p><Link to={routes.ACTIONS}>Akcije</Link></p>
        <p className='new_products'>Novi proizvodi</p>
        <p className='catalog' onClick={toggleCatalog}>Katalog</p>
        {show &&
        <div>
          <Catalog setShow={setShow} />
        </div>
        }
        
        <p className='contact_link'><Link to={routes.CONTACT}>Kontakt</Link></p>
      </div>
      <div className='number flex justify-end items-center col-span-2 md:col-span-1'>
      <Link to={routes.CONTACT} className='location flex items-center mr-5'>
          <GoLocation />
          <p>Prodavnice</p>
        </Link>
        <BsTelephone />
        <p>+381 18 551 511</p>
      </div>
    </div>
  )
}

export default BottomHeader