import React from 'react'

const ProductBanerAndMilk = ({data}) => {
  return (
    <div className='grid grid-cols-4 my-10'>
      <div className='baner_jogurt col-span-3'>
        {data.baners && data.baners.map((item, index)=>{
          return (
            <img key={index} src={item.image} alt={item.order} />
            
            )
          })}
      </div>
      <div className='jogurt flex justify-center items-center'>
      {data.products && data.products.map((item, index) =>{
      return(
        <div key={index}> 
        <div className='flex justify-center'>
          <img src={item.image} alt={item.name} />
        </div>
          <p>{item.name}</p>
          <p className='old_price'>{item.recommended_price} RSD</p>
          <p className='price'>{item.price} RSD</p>
          <p className='save_money'>Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD</p>
        </div>
      )
    })} 
      </div>
    
        
    </div>
  )
}

export default ProductBanerAndMilk