import React, {useState} from 'react'

const AdminPosts = ({posts}) => {
  const [show, setShow] = useState(false)
    const [ active, setActive ] = useState(null)
  return (
    <div className='grid grid-cols-1'>
        
        {posts && posts.map((item, index) =>{
            return(
                <div key={index} className='category'>
                    <div >
                        <p onClick={()=> {setShow(!show); setActive(index)}}>{item.name}</p>
                    </div>
                    {show && active === index &&
                    <div>
                        {item && posts[active].subcategories.map((sub, index) =>{
                            return(
                                <div key={index}> 
                                <p className='bg-green-400'>{sub.name}</p>
                                </div>
                                )
                        })}
                    </div>
                    }
                </div>
            )

        })}
        </div>         
  )
}

export default AdminPosts