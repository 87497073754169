import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import { getCategories } from '../../HTTP/https'
import routes from '../../routes'


const AllCategories = () => {
    const [data, setData] = useState('')
    
    const apiCall = async () =>{
      const {data} = await getCategories()
      setData(data)
    }
    
    useEffect(() =>{
        apiCall()
    },[])

  return (
    <div className='footer_all_categories container mt-10'>
        <h2>SVE KATEGORIJE</h2>
        <div className='mt-6'>
            {data && data.map((item, index) =>{
                return(
                        <Link to={routes.PRODUCT} key={index}>{item.name}</Link>
                )
            })}
        </div>

    </div>
  )
}

export default AllCategories