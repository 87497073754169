import React from 'react'

const ProductsTreeBaners = ({data}) => {
  return ( 
    <div className='flex justify-between relative overflow-hidden my-10'>
      {data && data.baners.map((item, index) =>{
        return(
          <div key={index}>
            <img src={item.image} alt={item.order} />
          </div>
          )
        })}
      
    </div>
  )
}

export default ProductsTreeBaners