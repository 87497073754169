import React from 'react'
import AllCategories from './AllCategories'
import BottomFooter from './BottomFooter'
import Contact from './Contact'
import './Footer.scss'
import Shops from './Shops'
import UsefulLinks from './UsefulLinks'

const Footer = () => {

  return (
    <div className='footer mt-6'>
      <div className='container mx-auto'>
        <div className='grid lg:grid-cols-4'>
          <div>
            <AllCategories />
          </div>
          <div>
          <UsefulLinks />  
          </div>
          <div>
          <Shops />
          </div>
          <div>
          <Contact />
          </div>
        </div> 
      </div>
        <BottomFooter />
    </div>
        
        
        
      
  )
}

export default Footer