import React, { useState, useEffect } from 'react'
import config from '../../config'
import { useParams } from 'react-router-dom'
import './Product.scss'
import ShowProducts from './ShowProducts'

const Product = () => {

  const [singleProduct, setSingleProduct] = useState([])
  const {name} = useParams()
    
useEffect(() =>{
  const apiCall = async () =>{
    const response = await fetch(config.api.requestProducts)
    const responseData = await response.json()
    const newProduct = responseData.data.find((product)=> product.name === name)
    setSingleProduct(newProduct)
  }

  apiCall()
}, [name])
console.log(singleProduct);

  return (
    <div className='single_product mt-16'>
      <div className='grid grid-cols-5'>
        <div className='col-span-2'>
          <div className='left_single_product flex justify-center items-center'>
          <img src={singleProduct?.image} />
          </div>
        </div>
        <div className='right_single_product col-span-3'>
          <div className='monthly_offer'>Ponuda važi od 13.05.2022 do 20.05.2022</div>
          <div className='single_product_name'>{singleProduct?.name}</div>
          <hr />
          <div className='mt-3'>
          <p>{singleProduct && singleProduct?.description?.short_description}</p>
          <div className='mt-6'>
            <p className='old_price'>{singleProduct?.recommended_price} RSD</p>
            <p className='price'>{singleProduct?.price} RSD</p>
            <p className='save_money'>Ušteda: {(singleProduct?.recommended_price - singleProduct.price).toFixed(2)}</p>
            <p className='pdv'>*PDV je uključen u cenu</p>
            <img src={singleProduct?.banner} />
          </div>
          </div>
        </div>
      </div>

    <div className='info_product mt-12'>
      <p>
        Informacija o proizvodu
      </p>
      <p>
        Deklaracija
      </p>
    </div>
    <div className='grid grid-cols-5 mt-9'>
      <div className='col-span-3'>
        <p>{singleProduct && singleProduct?.description?.long_description}</p>
        
      </div>
      <div className='col-span-2 flex justify-center items-center'>
        <div className='right_img flex justify-center items-center'>
          {singleProduct?.description?.long_description &&
          <img src={singleProduct?.image} alt={singleProduct?.name} />
          }
        </div>
      </div>
    </div>
    <ShowProducts data={singleProduct} />
    </div>
  )
}

export default Product