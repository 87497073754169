import React from 'react'
import icon from '../../../assets/icons/as-logo.png'
import './AdminHeader.scss'
import { VscAccount } from 'react-icons/vsc';
import { BsBell } from 'react-icons/bs';
import flag from '../../../assets/icons/serbia.png'
import { Link } from 'react-router-dom';


const AdminHeader = () => {
  
  return (
    <div className='admin_header'>
      <div className='admin_icon'>
        <Link to='/admin'>
          <img style={{height: '70px'}} src={icon} />
        </Link>
      </div>
      <div className='admin_acount'>
        <BsBell className='bell' />
        <img src={flag} alt='icon'/>
        <p>Srpski</p>
        <h4></h4>
        <VscAccount />
      </div>
    </div>
  )
}

export default AdminHeader