import config from "../../config"
import axiosInstance from "./AxiosInstance"

export const getApiProducts = async () =>{
   return await axiosInstance.get(config.baseURLAdmin + config.api.requestProducts)
   
   .then(async res => {
    return res.data
    }
    )
    .catch(err => {
    console.log('err', err);
    })
}

export const getApiBaners = async () =>{
   return await axiosInstance.get(config.baseURLAdmin + config.api.adminBaners)
   .then(async res => {
    return res.data
    }
    )
    .catch(err => {
    console.log('err', err);
    })
}

export const getApiCategories = async () =>{
   return await axiosInstance.get(config.baseURLAdmin + config.api.requestCategories)
   .then(async res => {
    return res.data
    }
    )
    .catch(err => {
    console.log('err', err);
    })
}