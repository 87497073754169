import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './Category.scss'
import logo from '../../assets/icons/as-logo.png'

const CategoryPage = () => {
  const {id} = useParams()
  const [data, setData] = useState('')
  const [dataCategories, setDataCategories] = useState('')
    

const apiCall = async () =>{
  const response = await fetch(`https://as-trgovina.jakovss.com/api/categories/${id}`)
  const dataResponse = await response.json()
  setData(dataResponse.data)
}

useEffect(() =>{
    apiCall()
},[])
const apiCallCategories = async () =>{
  const response = await fetch(`https://as-trgovina.jakovss.com/api/category-products/${id}`)
  const dataResponse = await response.json()
  setDataCategories(dataResponse.data)
}

useEffect(() =>{
  apiCallCategories()
},[])
  return (
  <>
    <div className='grid grid-cols-5 gap-5 mt-10'>
      {data && data.subcategories.map((item, index)=>{
        return(
          <div key={index}>
            <Link to={`/category-products/${item.id}`} className="flex flex-col justify-center items-center">
              <div className='category_product flex justify-center items-center'>
                {item?.image?.image?
                  <img src={item?.image?.image} alt={item.name}/>
                :<img src={logo} />}
              </div>
                <p>{item?.name}</p>
            </Link>
            </div>
        )
      })}
    </div>
    <div className='mt-10 grid grid-cols-5'>
    {dataCategories && dataCategories.map((item, index) =>{
      return(
          <Link to={`/product/${item.name}`} className='product_details mt-10' key={index}>
              <div className="rounded overflow-hidden flex flex-col items-center">
                <img src={item.image} alt="no-image" />
                <div className="px-6 py-4">
                <p className='product_name'>{item.name}</p>
                <p className='old_price'>{item.recommended_price} RSD</p>
                <p className='price'>{item.price} RSD</p>
                <p className='save_money'>Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD</p>
              </div>

            </div>
            </Link>
      )
    })}
    </div>
  </>
  )
}

export default CategoryPage