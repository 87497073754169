import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import './Catalog.scss'

const Catalog = ({setShow}) => {

    const [data, setData] = useState('')
    
    const apiCall = async () =>{
      const response = await fetch(config.api.requestCatalog)
      const dataResponse = await response.json()
      dataResponse.data.sort(function(a, b) {
        return a.order - b.order;
      });
      setData(dataResponse.data)
    }
    
    useEffect(() =>{
        apiCall()
    },[])

    console.log(data);

  return (


    <div className='opacity'>
        <div className='bg_opacity'></div>
        <div className='catalog'>

        {data && data.map((item, index) =>{
            return (
                <div key={index}>
                    <button className='close_catalog_btn' onClick={() => setShow(false)}></button>
                    <a href='https://as-trgovina.jakovss.com/storage/catalog/202209191356Izmene.pdf' target='_blank' className='take_baner'></a>
                    <img src={item.banner} alt={item.name} />
                </div>
            )
        })}
        </div>
    </div>
  )
}

export default Catalog