export default{
    baseURL: 'https://as-trgovina.jakovss.com/api/',
    baseURLAdmin: 'https://as-trgovina.jakovss.com/api/admin/',

    api:{
        requestHomeSections: `homepage-sections`,
        requestCategories: `categories`,
        requestBrands: `brands`,
        requestBaners: `banners`,
        requestProducts: `products`,
        requestActions: `discounts`,
        requestCatalog: `catalogs`,
        requestContact: `contact`,
        requestCategoryProducts: `category-products`,
        adminLogin: `login`,
        adminBaners:`banner-positions`,
    } 
}


// https://as-trgovina.jakovss.com/api/homepage-sections
// https://as-trgovina.jakovss.com/api/categories
// https://as-trgovina.jakovss.com/api/category-products
// https://as-trgovina.jakovss.com/api/products
// https://as-trgovina.jakovss.com/api/brands
// https://as-trgovina.jakovss.com/api/banners
// https://as-trgovina.jakovss.com/api/catalogs
// https://as-trgovina.jakovss.com/api/discounts
// https://as-trgovina.jakovss.com/api/contact

