import React from 'react'
import { FaGem } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';
import { BsEye, BsCurrencyDollar, BsBoxSeam } from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { ProSidebar, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './Sidebar.scss'
import { Link } from 'react-router-dom';
const SideMenu = () => {
  return (
        <ProSidebar className='sidebar_height'>
  <SidebarContent className='sidebar_content'>
    <Menu iconShape="square">

      <MenuItem icon={<BsBoxSeam />}><Link to='/admin/proizvodi'>Proizvodi</Link></MenuItem>
      <MenuItem icon={<BsEye />}><Link to='/admin/visibleOnSite'>Vidljivi Na Sajtu</Link></MenuItem>
        <MenuItem icon={<AiOutlineUnorderedList />}><Link to='/admin/categories'>Kategorije</Link></MenuItem>
        <MenuItem icon={<FaGem />}>Brendovi</MenuItem>
        <SubMenu title="Frontend" icon={<FiMonitor />}>
          <MenuItem>Frondend</MenuItem>
          <MenuItem>Cene</MenuItem>
        </SubMenu>
        <SubMenu title="Cene" icon={<BsCurrencyDollar />}>
          <MenuItem>Frondend</MenuItem>
          <MenuItem>Cene</MenuItem>
        </SubMenu>
    </Menu>
  </SidebarContent>
</ProSidebar>

  )
}

export default SideMenu