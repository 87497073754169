import React, { useState } from 'react'
import baner from '../../assets/images/black-friday.png'
import axios from 'axios'
import config from '../../config'

const ContactForm = () => {
    const url = config.baseURL + config.api.requestContact
    const [data, setData] = useState({
        name: "",
        email: "",
        message: ""
    })

    const submit = (e) =>{
        e.preventDefault()
        axios.post(url,{
            name: data.name,
            email: data.email,
            message: data.message
        })
        .then(res =>{
            console.log(res.data);
        })
    }

    const handleForm = (e) =>{
        const newData = {...data}
        newData[e.target.id] = e.target.value
        setData(newData)
    }
  return (
    <div className='contact_form grid grid-cols-1  md:grid-cols-2 mt-10'>
        <div>
            <h2 className='contact_us'>Kontaktirajte nas</h2>
            <div>
                <form onSubmit={(e) => submit(e)} className='flex flex-col mr-5'>
                    <input onChange={(e) => handleForm(e)} id='name' value={data.name} className='h-[40px] my-3'name='name' type='text' placeholder='Ime' required />
                    <input onChange={(e) => handleForm(e)} id='email' value={data.email} className='h-[40px] my-3' name='email' type='email' placeholder='E-mail adressa.' required />
                    <textarea onChange={(e) => handleForm(e)} id='message' value={data.textarea} className='h-[170px] my-3' name='message' type='text' placeholder='Vasa poruka.' required />
                    <button type='submit'>Posalji</button>
                </form>
            </div>
        </div>
        <div>
            <img className='mt-5 md:mt-0' src={baner} alt='contact baner' />
        </div>
    </div>
  )
}

export default ContactForm