import React, {useState, useEffect} from 'react'
import { getApiCategories } from '../../HTTP/api'
import '../../components/AllPages/AllPages.scss'
import AdminPagination from './AdminPagination'
import AdminPosts from './AdminPosts'

const AdminCategories = () => {
    const [data, setData] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(15)


const apiCall = async () =>{
  const {data} = await getApiCategories() 
    setData(data)
}

useEffect(() =>{
    apiCall()
},[])

const paginate = (pageNumber) =>{
    setCurrentPage(pageNumber)
  }

const indexOfLastPost = currentPage * postsPerPage;
const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className='all_content'>
        
        <AdminPosts posts={currentPosts} />
        <AdminPagination 
            postsPerPage={postsPerPage} 
            totalPosts={data.length} 
            paginate={paginate}
            currentPage={currentPage}
        /> 
          
    </div>
  )
}

export default AdminCategories