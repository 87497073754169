import React from 'react'

const ProductTreeAndFour = ({data}) => {
  return (
    <div className='my-14 flex justify-between'>
      {data.baners && data.baners.map((item, index) =>{
        return(
          <div key={index}>
            <img src={item.image} alt={item.name} />
          </div>
        )
      })}
    </div>
  )
}

export default ProductTreeAndFour