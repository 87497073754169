import React from 'react'
import banerImg from '../../assets/images/Frame.png'
import img from '../../assets/images/knjazevacka.png'
import img1 from '../../assets/images/prvomajska.png'
import img2 from '../../assets/images/somborka.png'
import icon from '../../assets/icons/Ellipse.png'

const ContactBaner = () => {
  return (
    <div className='mt-5'>
        <div>
            <img src={banerImg} alt='banerImg'/>
        </div>
        <div className='contact mt-11'>
        <p className='brands flex justify-start items-center'>
          <img className='mr-2' src={icon} alt='icon' />Kontakt
          </p>
        </div>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5'>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img} alt='banerImg' />
                </div>
                <div className='ml-10'>
                    <h2>Knjazevacka BB</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img1} alt='banerImg' />
                </div>
                <div className='ml-10'>
                    <h2>SOMBORSKA 47A</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img2} alt='banerImg'/>
                </div>
                <div className='ml-10'>
                    <h2>Carnojevica 10</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img1} alt='banerImg' />
                </div>
                <div className='ml-10'>
                    <h2>Prvomajska 1</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img2} alt='banerImg' />
                </div>
                <div className='ml-10'>
                    <h2>HILANDARSKA 27</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img} alt='banerImg' />
                </div>
                <div className='ml-10'>
                    <h2>Sime Dinica 3</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
            <div className='grid grid-cols-2 mt-3'>
                <div className='img'>
                    <img src={img2} alt='banerImg' />
                </div>
                <div className='ml-10'>
                    <h2>Milosa Obilica</h2>
                    <p>Nis</p>
                    <br />
                    <p>Email:</p>
                    <p>info@asmaloprodaja.com</p>
                    <br />
                    <p>Telefon:</p> 
                    <p>018 551 551</p> 
                    <p>018 551 950</p> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactBaner