import React from 'react'
import { Link } from 'react-router-dom';
import icon from '../../assets/icons/Ellipse.png'



const ShowProducts = ({data}) => {
  return (
    <div className='mt-12'>
        <div className='connected_products'>
            <p className='swiper_product_name flex justify-start items-center'>
                <img className='mr-2' src={icon} />Povezani proizvodi
            </p>
            <div className='flex mt-10'>

            {data?.connectedProducts && data?.connectedProducts?.map((item, index) =>{
                return (
                    <div key={index}>
                            <Link to={`/product/${item.name}`} >
                            <div className="rounded overflow-hidden flex flex-col items-center">
                            <img className="h-[180px]" src={item && item.image} alt="no-image" />
                            <div className="px-6 py-4">
                                <p className='product_name'>{item.name}</p>
                                <p className='old_price'>{item.recommended_price} RSD</p>
                                <p className='price'>{item.price} RSD</p>
                                <p className='save_money'>Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD</p>
                            </div>
                            </div>
                            </Link>
                        </div>
                )
            })}
            </div>
        </div>
        
        <div className='connected_products mt-12'>

            <p className='swiper_product_name flex justify-start items-center'>
                <img className='mr-2' src={icon} />Preporuceni proizvodi
            </p>
            <div className='flex mt-10'>

            {data.similarProducts && data.similarProducts.map((item, index) =>{
                return (
                    <div key={index}>
                            <Link to={`/product/${item.name}`} >

                            <div className="rounded overflow-hidden flex flex-col items-center">
                            <img className="h-[180px]" src={item && item.image} alt="no-image" />
                            <div className="px-6 py-4">
                                <p className='product_name'>{item.name}</p>
                                <p className='old_price'>{item.recommended_price} RSD</p>
                                <p className='price'>{item.price} RSD</p>
                                <p className='save_money'>Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD</p>
                            </div>
                            </div>
                            </Link>
                        </div>
                )
            })}
            </div>
        </div>


    </div>
  )
}

export default ShowProducts