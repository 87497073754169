import React from 'react'
import icon from '../../assets/icons/guarantee.png'
import icon1 from '../../assets/icons/fast-moving-consumer-goods.png'
import icon2 from '../../assets/icons/discount.png'
import icon3 from '../../assets/icons/card-payment.png'

const OurOffer = () => {
  return (
    <div className='our_offer mt-7 grid grid-cols-4 '>
        <div className='flex justify-center items-center'>
            <img src={icon} alt='icon' />
            <p className='mx-3'>Kvalitet proizvoda</p>
        </div>
        <div className='flex justify-center items-center'>
            <img src={icon1} alt='icon' />
            <p className='mx-3'>Širok izbor asortimana</p>
        </div>
        <div className='flex justify-center items-center'>
            <img src={icon2} alt='icon' />
            <p className='mx-3'>Uvek niske cene</p>
        </div>
        <div className='flex justify-center items-center'>
            <img src={icon3} alt='icon' />
            <p className='mx-3'>Različite opcije plaćanja</p>
        </div>
    </div>
  )
}

export default OurOffer